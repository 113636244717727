<template>
    <div class="MultipleCom-box allh flex column border-box" :key="GameId">
        <div class="flex column flex1">
            <div :class="[LangeuageAgent === 'CH' ? 'font20' : 'font16']" class="gamename flex items-center content-between border-box">
                <span>{{TableInfo.name}} {{TableInfo.gameId}}</span>
                <p class="flex items-center">
                    <span @click="ToInGame" class="flex items-center content-center pointer">{{Lang['JRYX']}}<!--进入游戏--></span>
                    <!--                    <span class="font22">{{CopyTableInfo.betTime}}</span>-->
                </p>
            </div>
            <div class="flex1 relative">
                <SelfVideo :TableInfo="CopyTableInfo"></SelfVideo>
            </div>
            <div>
                <MultiplePanel :TableInfo="TableInfo"></MultiplePanel>
            </div>
        </div>
        <div class="bottombox flex column">
            <div class="relative">
                <components
                        :is="CurrentBet"
                        :ref="'Bet'+Count"
                        :TableInfo="TableInfo"
                        :SetBetState="SetBetState"
                        :CloseBetState="CloseBetState"
                        :Count="Count"
                        :GameId="GameId">
                </components>
                <BetNotice :BetState="BetState" :Profit="Profit"></BetNotice>
            </div>
            <div class="chippond">
                <MultipleChip :Count="Count" :ShowChip="ShowChip"></MultipleChip>
            </div>
            <div class="betconfirm flex items-center">
                <div v-for="(item,index) in BetConfirm" :key="index" @click="BetControl(index)"
                     :class="[LangeuageAgent === 'CH' ? 'font20' : 'font14']"
                     class="flex content-center items-center colorfff pointer">
                    <img :src="'./img/Multiple/'+item.img+'.png'" class="mar10">
                    {{Lang[item.btn]}}
                </div>
            </div>
            <div class="flex1 relative">
                <components :is="CurrentBottom" :TableInfo="TableInfo"></components>
                <span :class="{'spancircle1':CopyTableInfo.betTime > 0}"
                      class="absolute colorccc font35 position-center zindex3">
				  {{CopyTableInfo.betTime <= 0 ? GetGameStatus(CopyTableInfo) : CopyTableInfo.betTime}}
              </span>
            </div>
        </div>
        <SetChip v-show="ShowChipPond" :ShowChip="ShowChip" :ShowChipPond="ShowChipPond" :MaxNum="4"
                 :Count="Count"></SetChip>
    </div>
</template>

<script>
    import {mapState} from 'vuex';

    export default {
        name: 'index',
        props: {
            'GameId': {
                default() {
                    return ''
                },
                type: Number
            },
            'Count': [Number],
            'MainOutRoom': [Function],
            'NextRoom': [Function],
        },
        components: {
            MultiplePanel: () => import('../TablePanel/MultiplePanel'),
            MultipleChip: () => import('../BetTools/MultipleChip'),
            SelfVideo: () => import('../SelfVideo'),
            MultipleBaccarat: () => import('../Bet/MultipleBaccarat'),
            MultipleLonghuLake: () => import('../Bet/MultipleLonghuLake'),
            MultipleSameSize: () => import('../Bet/MultipleSameSize'),
            MultipleSingleDouble: () => import('../Bet/MultipleSingleDouble'),
            MultipleCattle: () => import('../Bet/MultipleCattle'),
            MultiplePushBobbin: () => import('../Bet/MultiplePushBobbin'),
            MultipleLZBaccarat: () => import('../Dew/MultipleLZBaccarat'),
            MultipleLZCattle: () => import('../Dew/MultipleLZCattle'),
            MultipleLZThreeCounsellors: () => import('../Dew/MultipleLZThreeCounsellors'),
            MultipleLZPushBobbin: () => import('../Dew/MultipleLZPushBobbin'),
            SetChip: () => import('@/components/SetIng/SetChip'),
            BetNotice: () => import('../BetTools/BetNotice'),
        },
        computed: {
            ...mapState(['InsideGame', 'SocketFn', 'UserInfo', 'DefaultConfig', 'LangeuageAgent'])
        },
        data() {
            return {
                CallBack: ['CallInRoomFn'],
                BetState: '',  //0 1 表示下注成功和最低限红  2开始下注 3停止下注   4盈亏通知
                CurrentBet: '', //当前下注的组件
                CurrentBottom: '', //当前露珠组件
                Profit: 0,
                ShowChipPond: false,
                TableInfo: {},
                CopyTableInfo: {
                    betTime: 0,
                    cameraUrl: ''
                },
                FirstIn: true,
                Timer: null,
                BetConfirm: [
                    {btn: 'QD', img: 'xz_qr'},//确定
                    {btn: 'QX', img: 'xz_qx'},//取消
                    // {btn: 'CXXZ', img: 'xz_gb'},//撤销下注
                ],
                GameType: [
                    {
                        name: 'BJL', params: {  //百家乐
                            'hallType': 1,
                            'gameType': 1,
                            'url': 'Baccarat',
                            'videoSize': 'small',
                            'currentTypeName': 'Baccarat'
                        }
                    },
                    {
                        name: 'JSBJL', params: {   //极速·百家乐
                            'hallType': 2,
                            'gameType': 1,
                            'url': 'BaccaratSpeed',
                            'videoSize': 'big',
                            'currentTypeName': 'Baccarat'
                        }
                    },
                    {
                        name: 'LH', params: {   //龙虎
                            'hallType': 1,
                            'gameType': 2,
                            'url': 'LonghuLake',
                            'videoSize': 'small',
                            'currentTypeName': 'LonghuLake'
                        }
                    },
                    {
                        name: 'JSLH', params: {    //极速·龙虎
                            'hallType': 2,
                            'gameType': 2,
                            'url': 'LonghuLakeSpeed',
                            'videoSize': 'big',
                            'currentTypeName': 'LonghuLake'
                        }
                    },
                    {
                        name: 'NN', params: {   //牛牛
                            'hallType': 2,
                            'gameType': 3,
                            'url': 'Cattle',
                            'videoSize': 'small',
                            'currentTypeName': 'Cattle'
                        }
                    },
                    {
                        name: 'SG', params: {   //三公
                            'hallType': 2,
                            'gameType': 6,
                            'url': 'ThreeCounsellors',
                            'videoSize': 'small',
                            'currentTypeName': 'ThreeCounsellors'
                        }
                    },
                    {
                        name: 'DXD', params: {  //大小点
                            'hallType': 1,
                            'gameType': 5,
                            'url': 'SameSize',
                            'videoSize': 'big',
                            'currentTypeName': 'SameSize'
                        }
                    },
                    {
                        name: 'DS', params: {   //单双
                            'hallType': 1,
                            'gameType': 4,
                            'url': 'SingleDouble',
                            'videoSize': 'big',
                            'currentTypeName': 'SingleDouble'
                        }
                    },
                    {
                        name: 'TTZ', params: {  //推筒子
                            'hallType': 1,
                            'gameType': 7,
                            'url': 'PushBobbin',
                            'videoSize': 'small',
                            'currentTypeName': 'PushBobbin'
                        }
                    }],
            }
        },
        created() {
            this.$nextTick(() => {
                let GameSpeedOrOrdinary = [];
                if (this.DefaultConfig.GameSpeedOrOrdinary) {
                    this.DefaultConfig.GameSpeedOrOrdinary.forEach((item, index) => {
                        if (item) {
                            this.GameType[index].params.videoSize = item === '1' ? 'small' : 'big';
                            GameSpeedOrOrdinary.push(this.GameType[index]);
                        }
                    });
                    this.GameType = this.Clone(GameSpeedOrOrdinary);
                }
                this.InRoom();
            });
        },
        beforeDestroy() {
            if (sessionStorage.NeedLeave !== 'false') this.OutRoom();
        },
        watch: {
            'GameId': {
                handler(n, o) {
                    // console.log('变化了吗');
                    // console.log(n);
                }, deep: true
            },
            'InsideGame': {
                handler(n, o) {
                    let copyn = this.Clone(n);
                    let val = this.Clone(n);
                    if (copyn.hasOwnProperty(this.GameId)) {  //表示推送的是当前桌子
                        let _TableInfo = this.Clone(this.TableInfo);
                        if (_TableInfo.hasOwnProperty('profit')) delete _TableInfo['profit']; //消除盈亏
                        if (_TableInfo.hasOwnProperty('betInfo')) delete _TableInfo['betInfo']; //消除历史下注
                        _TableInfo = this.Clone({..._TableInfo, ...copyn[this.GameId]});
                        _TableInfo.change = false;
                        if (copyn[this.GameId].hasOwnProperty('roadList')) {
                            _TableInfo.change = true;
                            copyn[this.GameId].roadList = copyn[this.GameId].roadList || [];
                            _TableInfo.transForm = this.Calculation.road(copyn[this.GameId].roadList, this.TableInfo.type);
                        }
                        delete val[this.GameId];
                        this.SetStore({
                            Prop: 'InsideGame',
                            Val: val
                        });
                        this.TableInfo = this.Clone(_TableInfo);
                    }
                }, deep: true
            },
            'TableInfo': {
                handler(n, o) {
                    if (this.GameId !== n.gameId) return;
                    this.CopyTableInfo.cameraUrl = n.cameraUrl; //直接进行视频路径更新 防止后面呢return
                    let _this = this;
                    try {
                        if (n.gameStatus !== o.gameStatus) {
                            if (n.gameStatus === 1) {
                                _this.SetBetState(2); //开始下注
                                _this.CloseBetState('');
                            }
                            if (n.gameStatus === 2) { //停止下注
                                _this.SetBetState(3); //停止下注
                                _this.CloseBetState('');
                                // if(this.copyBetMoney.filter(item=>item>0).length === 0) this.setBetState();  //本局未下注
                                _this.BetControl(1); //清除虚假下注金额
                            }
                            if (n.gameStatus !== 2) _this.BetControl(3);  //只要不是开牌中  都清桌面
                            // if (n.gameStatus === 3) { //播报结果语音
                            //     this.BetControl(4, n.roadList[n.roadList.length - 1]);
                            // }
                        }
                        if (n.hasOwnProperty('profit')) { //盈亏通知
                            _this.Profit = Number(n.profit.toFixed(2));
                            _this.SetStore({
                                Prop: 'UserInfo',
                                Val: _this.Clone({..._this.UserInfo, ...{balance: n.balance}})
                            }); //更新余额
                            _this.SetBetState(4);
                            _this.CloseBetState('');
                        }
                    } catch (e) {

                    }
                    if (!this.FirstIn) if (n.gameId === o.gameId && n.gameStatus === o.gameStatus && n.betTime === o.betTime) return;
                    this.FirstIn = false;
                    clearInterval(this.Timer);
                    this.CopyTableInfo = this.Clone(n);
                    if (n.betTime > 0 && n.gameStatus === 1) this.Timer = setInterval(() => {   //倒计时
                        this.CopyTableInfo.betTime--;
                        if (this.CopyTableInfo.betTime <= 0) clearTimeout(this.Timer);
                    }, 1000);
                    if (n.gameStatus !== 1) {
                        this.CopyTableInfo.betTime = 0;
                    }
                }, deep: true
            },
        },
        methods: {
            InRoom() {
                this.SocketApi.SendSock([this.GameId, this.cmd.EnterRoom, 0, {'GameId': this.GameId}], 'CallInRoomFn');
            },
            CallInRoomFn(res) {
                if (this.GameId !== res.gameId) return;
                let TableInfo = this.Clone(res);
                this.GameType.forEach((item, index) => {
                    let {params} = item;
                    if (params.hallType === res.hallType && params.gameType === res.gameType) {
                        TableInfo.name = this.Lang[item.name];
                        TableInfo.type = params.currentTypeName;
                        TableInfo.currentTypeName = params.currentTypeName;
                        TableInfo.videoSize = params.videoSize;
                        TableInfo.currentType = index;
                        TableInfo.url = params.url;
                        if (['Cattle', 'ThreeCounsellors'].includes(params.currentTypeName)) {  //下注区域
                            this.CurrentBet = `MultipleCattle`;
                        } else {
                            this.CurrentBet = `Multiple${params.currentTypeName}`;
                        }
                        if (['Baccarat', 'LonghuLake', 'SameSize', 'SingleDouble'].includes(params.currentTypeName)) {  //露珠区域
                            this.CurrentBottom = `MultipleLZBaccarat`;
                        } else {
                            this.CurrentBottom = `MultipleLZ${params.currentTypeName}`;
                        }
                    }
                });
                this.TableInfo = this.Clone(TableInfo);
                this.NextRoom();
            },
            OutRoom() {
                this.SocketApi.SendSock([this.GameId, this.cmd.ExitRoom, 0, {'gameId': this.GameId}]);
            },
            SetAudio(url) {
                this.HandlePlayAudio(url);
            },
            ShowChip() {
                this.ShowChipPond = !this.ShowChipPond;
            },
            BetControl(index, n) {
                let CurrentTable = this.$refs['Bet' + this.Count];
                if (index === 0) CurrentTable.ConfirmBet(); //确认下注
                if (index === 1) CurrentTable.CancelBet();  //取消下注
                if (index === 2) CurrentTable.RevokeBet();  //撤销下注
                if (index === 3) CurrentTable.ClearTable();  //清空桌面
                // if (index === 4) CurrentTable.PlayResult(n);  //播放结果语言
            },
            SetBetState(val) {
                clearTimeout(this.Timer1);
                this.BetState = val;
            },
            CloseBetState() {
                this.Timer1 = setTimeout(() => {
                    this.SetBetState('');
                }, 2000);
            },
            SetOpacity() {
                this.Opacity1 = false;
                setTimeout(() => {
                    this.Opacity1 = true;
                }, 300);
            },
            ToInGame() {
                let {url, videoSize, currentTypeName, currentType} = this.TableInfo;
                localStorage.currentType = currentType;
                localStorage.type = currentTypeName;
                localStorage.videoSize = videoSize;
                sessionStorage.NeedLeave = false;
                this.MainOutRoom().then(() => {
                    this.RouterTo('GameIn', {'id': this.TableInfo.gameId});
                });
            }
        }
    }
</script>

<style lang="less">
    .MultipleCom-box {
        width: 25%;
        background-color: #1e1818;
        border: 1px solid #9e9797;

        .gamename {
            height: .6rem;
            background: url("../../../public/img/Multiple/dt_k_bg.png") no-repeat left center;
            padding: 0 .1rem;

            > span:first-child {
                color: #af8868;
                padding-left: .51rem;
                line-height: .43rem;
                background: url("../../../public/img/Multiple/dt_c.png") no-repeat left center/.43rem .43rem;
            }

            p {
                span:first-child {
                    color: #e6c588;
                    /*margin-right: .35rem;*/
                    width: 1.4rem;
                    height: .4rem;
                    text-indent: .3rem;
                    background: url("../../../public/img/Multiple/dt_jryx_btn.png") no-repeat left center/contain;

                    &:hover {
                        background: url("../../../public/img/Multiple/dt_jryx_btn_hover.png") no-repeat left center/contain;
                    }
                }

                span:nth-child(2) {
                    color: #cb2934;
                    width: .4rem;
                    text-align: end;
                }
            }
        }

        .bottombox {
            height: 5.6rem;

            .chippond {
                height: 1rem;
            }

            .betconfirm {
                height: .6rem;
                background-color: #125238;
                justify-content: space-around;

                > div {
                    width: 1.26rem;
                    height: .4rem;

                    &:nth-child(1) {
                        background: url("../../../public/img/Multiple/dt_queding_btn.png") no-repeat top center/contain;

                        &:hover {
                            background: url("../../../public/img/Multiple/dt_queding_btn_hover.png") no-repeat top center/contain;
                        }
                    }

                    &:nth-child(2) {
                        background: url("../../../public/img/Multiple/dt_quxiao_btn.png") no-repeat top center/contain;

                        &:hover {
                            background: url("../../../public/img/Multiple/dt_quxiao_btn_hover.png") no-repeat top center/contain;
                        }
                    }

                    &:nth-child(3) {
                        background: url("../../../public/img/Multiple/dt_chexiao_btn.png") no-repeat top center/contain;

                        &:hover {
                            background: url("../../../public/img/Multiple/dt_chexiao_btn_hover.png") no-repeat top center/contain;
                        }
                    }
                }
            }
        }
    }
</style>
