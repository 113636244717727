<template>
    <div class="Multiple allh allw flex">
        <LeftCenter></LeftCenter>
        <div class="flex1 flex column">
            <Notice></Notice>
            <div class="flex1 flex border-box">
                <div class="flex column flex1">
                    <div class="flex1 relative">
                        <div class="content-box absolute allw allh overflowY flex warp flex-start">
                            <MultipleCom v-for="(item,index) in MultipleGameId" :key="item" :GameId="item"
                                         :Count="index" :MainOutRoom="MainOutRoom" :NextRoom="Init"></MultipleCom>
                            <!--                            <MultipleCom v-if="MultipleGameId[0]" :key="MultipleGameId[0]" :GameId="MultipleGameId[0]" :Count="0"></MultipleCom>-->
                            <!--                            <MultipleCom v-if="MultipleGameId[1]" :key="MultipleGameId[1]" :GameId="MultipleGameId[1]"-->
                            <!--                                         :Count="1"></MultipleCom>-->
                            <!--                            <MultipleCom v-if="MultipleGameId[2]" :key="MultipleGameId[2]" :GameId="MultipleGameId[2]"-->
                            <!--                                         :Count="2"></MultipleCom>-->
                            <!--                            <MultipleCom v-if="MultipleGameId[3]" :key="MultipleGameId[3]" :GameId="MultipleGameId[3]"-->
                            <!--                                         :Count="3"></MultipleCom>-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ChooseTable :ArrowTable="ArrowTable" :NeedMultiple="true"></ChooseTable>
        <router-view></router-view>
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    import MultipleCom from '@/components/MultipleCom';
    import LeftCenter from '@/components/LeftCenter';
    import Notice from '@/components/Notice';
    import ChooseTable from '../../components/ChangeTable/ChooseTable';

    export default {
        name: 'index',
        components: {MultipleCom, LeftCenter, Notice, ChooseTable},
        computed: {...mapState(['MultipleId', 'SocketFn'])},
        data() {
            return {
                CallBack: ['CallOutRoomFn'],
                MultipleGameId: [],
                testind: [25, 201],
                ArrowTable: false,  //允许请求大厅资源
                RoomIndex: 0,    //房间请求顺序
                OutIndex: 0,    //退出房间请求顺序
                NeedTime: false
            }
        },
        created() {
            this.$nextTick(() => {
                localStorage.currentType = 0;
                this.Init(0);
            });
        },
        watch: {
            'MultipleId': {
                handler(n, o) {
                    n.forEach((item, index) => {
                        if (item !== o[index]) this.RoomIndex = index;
                    });
                    this.Init();
                }, deep: true
            },
            'OutIndex': {
                handler(n, o) {
                    if (this.OutIndex === 4) return;
                    setTimeout(() => {
                        this.SocketApi.SendSock([this.MultipleId[this.OutIndex], this.cmd.ExitRoom, 0, {'gameId': this.MultipleId[this.OutIndex]}], 'CallOutRoomFn');
                    }, 50);
                }, deep: true
            }
        },
        beforeRouteEnter(to, from, next) {
            //在next中写处理函数
            next((vm) => {
                if (from.name === 'GameIn') vm.NeedTime = true;
            }); // err 与 12134 是随便传的值， 可忽略
        },
        methods: {
            Init() {
                setTimeout(() => {
                    this.MultipleGameId.splice(this.RoomIndex, 1, this.MultipleId[this.RoomIndex]);
                    // this.MultipleGameId.splice(this.RoomIndex, 1, this.testind[this.RoomIndex]);
                    if (this.RoomIndex < 3) {
                        this.NeedTime = false;
                        this.RoomIndex += 1;
                    } else {
                        this.ArrowTable = true;
                    }
                }, this.NeedTime ? 650 : 300);
            },
            MainOutRoom() {  //退出房间总开关
                this.OutIndex = 0;
                return new Promise((resolve, reject) => {
                    this.SocketApi.SendSock([this.MultipleId[this.OutIndex], this.cmd.ExitRoom, 0, {'gameId': this.MultipleId[this.OutIndex]}], 'CallOutRoomFn');
                    let Interval = setInterval(() => {
                        if (this.OutIndex === this.MultipleId.length) {
                            clearInterval(Interval);
                            setTimeout(() => {
                                resolve();
                            }, 50);
                        }
                    }, 50);
                });
            },
            CallOutRoomFn() {
                this.OutIndex += 1;
            }
        }
    }
</script>

<style lang="less">
    .Multiple {
        .gamebox {
            width: 25%;
            background-color: #8e8d8a;
        }
    }
</style>
